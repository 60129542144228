import { css } from '@emotion/react';


export const container = css`
  position: relative;

  display: flex;
  padding: 0 105px 180px;

  @media all and (max-width: 1024px) {
    display: block;
    padding: 0 10% 160px;
  }

  @media all and (max-width: 640px) {
    display: block;
    padding: 0 6% 120px;
    overflow-x: hidden;
  }
`;


export const floatingContainer = css`
  display: block;
  padding-right: 20px;
  flex-shrink: 0;

  padding-bottom: var(--padding-bottom);

  @media all and (max-width: 1024px) {
    display: none;
  }
`;

export const floatingTitle = css`
  position: sticky;
  top: 100px;
  left: 0;

  font-weight: 700;
  font-size: 72px;
  line-height: 74px;
  color: white;

  white-space: nowrap;
  z-index: 2;
`;


export const section = css`
  position: relative;

  margin-bottom: 200px;
  z-index: 1;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 120px;
  }
`;

export const mobileTitle = css`
  display: none;

  margin-bottom: 6px;

  font-weight: 600;
  color: rgba(255, 255, 255, 0.66);

  @media all and (max-width: 1024px) {
    display: block;
    font-size: 48px;
  }

  @media all and (max-width: 640px) {
    display: block;
    font-size: 28px;
  }
`;

export const heading = css`
  display: block;

  margin-bottom: 48px;

  font-weight: 700;
  font-size: 72px;
  line-height: 74px;

  background: linear-gradient(
    108.79deg,
    #ffffff 24.05%,
    rgba(255, 255, 255, 0) 118.78%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media all and (max-width: 1024px) {
    margin-bottom: 42px;
    font-size: 54px;
    line-height: 54px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 36px;
    font-size: 36px;
    line-height: 36px;
  }
`;

export const subheading = css`
  display: block;
  font-size: 56px;
  line-height: 64px;

  @media all and (max-width: 1150px) {
    font-size: 36px;
    line-height: 36px;
  }

  @media all and (max-width: 1024px) {
    font-size: 48px;
    line-height: 48px;
  }

  @media all and (max-width: 640px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const gatewayHeading = css`
  margin-bottom: 0 !important;
`;


export const principalContainer = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 48px;
  margin-bottom: 24px;

  @media all and (max-width: 1024px) {
    display: block;
  }
`;

export const principalCard = css`
  font-size: 18px;
  line-height: 28px;
  color: white;

  @media all and (max-width: 1024px) {
    padding-right: 40px;
    margin-bottom: 48px;
  }

  @media all and (max-width: 640px) {
    padding-right: 40px;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const principalHeading = css`
  font-weight: 600;
  margin-bottom: 8px;

  @media all and (max-width: 1024px) {
    margin-bottom: 4px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 0;
  }
`;

export const principalIllust = css`
  height: 32px;
  margin-bottom: 16px;

  @media all and (max-width: 1024px) {
    margin-bottom: 10px;
  }

  @media all and (max-width: 640px) {
    height: 24px;
    margin-bottom: 4px;
  }
`;

export const learnMore = css`
  display: inline-flex;
  align-items: center;

  height: 32px;

  font-weight: 600;
  font-size: 20px;
  color: white;
  text-decoration: underline;
  transition: .18s all ease;

  span {
    margin-right: 12px;
    line-height: 1;
  }

  &:hover {
    opacity: 0.6;
  }

  @media all and (max-width: 640px) {
    font-size: 18px;

    span {
      margin-right: 8px;
    }

    svg {
      width: auto;
      height: 18px;
    }
  }
`;


export const gatewayContainer = css`
  display: grid;
  grid-template-columns: repeat(2, 320px);
  grid-column-gap: 32px;
  margin-bottom: 32px;

  @media all and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-row-gap: 16px;
  }

  @media all and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    margin-bottom: 16px;
  }

  @media all and (max-width: 640px) {
    display: block;
    margin-bottom: 0;
  }
`;

export const gatewayCard = css`
  position: relative;
  padding: 80px 24px 24px;

  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(40px);
  border-radius: 8px;

  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);

  @media all and (max-width: 640px) {
    padding: 80px 20px 24px;
    margin-bottom: 16px;
  }
`;

export const gatewayIllust = css`
  position: absolute;
  top: 12px;
  right: 16px;

  height: 110px;
  mix-blend-mode: luminosity;
`;

export const gatewayIllustTeam = css`
  position: absolute;
  top: 12px;
  right: 24px;

  height: 96px;
  mix-blend-mode: luminosity;
`;

export const gatewayTitle = css`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, .66);
`;

export const comingSoon = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.5);

  @media all and (max-width: 640px) {
    margin-left: 8px;
    font-size: 16px;
    line-height: 24px;
  }
`;


export const tokenIllustWrapper = css`
  position: absolute;
  width: 100vw;
  height: 2000px;
  margin-top: -2000px;
  overflow-x: hidden;

  @media all and (min-width: 1300px) {
    margin-left: calc((100vw - 1300px) * -0.5);
  }

  @media all and (max-width: 640px) {
    margin-top: -1000px;
    height: 1000px;
  }
`;

export const tokenOne = css`
  position: absolute;
  bottom: 200px;
  left: -150px;

  width: 500px;

  @media all and (max-width: 1024px) {
    bottom: 200px;
    left: -80px;
    width: 400px;
  }

  @media all and (max-width: 640px) {
    bottom: 50px;
    left: -40px;
    width: 300px;
  }
`;

export const tokenTwo = css`
  position: absolute;
  bottom: 30px;
  right: 300px;

  width: 250px;

  @media all and (max-width: 1024px) {
    bottom: 20px;
    right: 150px;
    width: 225px;
  }

  @media all and (max-width: 640px) {
    right: 0;
    bottom: 0;
    width: 200px;
  }
`;

export const tokenThree = css`
  position: absolute;
  bottom: 500px;
  right: -100px;

  width: 550px;

  @media all and (max-width: 1024px) {
    bottom: 350px;
    right: -80px;
    width: 350px;
  }

  @media all and (max-width: 640px) {
    bottom: 500px;
    right: -40px;
    width: 200px;
  }
`;
